const config = {
  dev: {
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://x5oh45rem1.execute-api.us-east-2.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_V5whjGDgd",
      APP_CLIENT_ID: "537vo5uhgul2bha4d11fpedtdk",
      IDENTITY_POOL_ID: "us-east-2:e89c47ed-8c4a-4019-9d0d-7c59186fdb95"
    },
    stripe: {
      KEY: "pk_test_XudIFgkvGOA8dWf3DPsHcRXZ005df3NlAE"
    }
  },
  test: {
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://jyhgeug40h.execute-api.us-east-2.amazonaws.com/test"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_9PgqFbZZX",
      APP_CLIENT_ID: "2k2ir1kuggvc1j664or9s0v97d",
      IDENTITY_POOL_ID: "us-east-2:be3495d9-9bf9-467f-a0f6-e70d42d943d1"
    },
    stripe: {
      KEY: "pk_test_XudIFgkvGOA8dWf3DPsHcRXZ005df3NlAE"
    }
  },
  prod: {
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://pwa3wv7p56.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_Pe24scgjy",
      APP_CLIENT_ID: "243jf24mmrd1qr9k376eavr1k",
      IDENTITY_POOL_ID: "us-east-2:883b9c4a-e767-4e01-a2e2-09caca08210a"
    },
    stripe: {
      KEY: "??????????????????"
    }
  }
};

const envConfig = config[process.env.REACT_APP_STAGE] || config.dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...envConfig
};
