import React from "react";
import CenteredCircularProgress from "./CenteredCircularProgress";

function ComponentLoadingIndicator({error, timedOut, pastDelay, retry, classes}) {
  if (error) {
    return <div>Error! <button onClick={ retry }>Retry</button></div>;
  } else if (timedOut) {
    return <div>Taking a long time... <button onClick={ retry }>Retry</button></div>;
  } else if (pastDelay) {
    return <CenteredCircularProgress />;
  } else {
    return null;
  }
};

export default ComponentLoadingIndicator;
