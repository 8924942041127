import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ProfileIcon from '@material-ui/icons/AssignmentInd';
import UserIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const options = [
  {
    title: 'My Profile',
    icon: <ProfileIcon color="primary" />,
    target: '/profile'
  },
  {
    title: 'Logout',
    icon: <LogoutIcon color="primary" />,
    target: 'logout'
  }
];

const styles = theme => ({
  navButton: {
    position: 'absolute',
    right: '10px'
  },
  navIcon: {
    marginLeft: theme.spacing(1)
  }
});

function Nav({ classes, user, logout, history }) {
  const [menuLink, setMenuLink] = useState(null);

  function handleMenu(event) {
    setMenuLink(event.currentTarget);
  };

  function handleMenuClose() {
    setMenuLink(null);
  };

  function handleMenuSelection(target) {
    handleMenuClose();
    if (target === 'logout') {
      logout();
    } else {
      history.push(target);
    }
  };

  const isMenuOpen = Boolean(menuLink);

  return (
    <>
      <IconButton
        aria-owns={isMenuOpen ? 'main-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        aria-label="Menu"
        className={classes.navButton}
      >
        <Typography component="div" variant="caption">{user && user.firstName} {user && user.lastName}</Typography>
        <UserIcon className={classes.navIcon} />
      </IconButton>
      <Menu id="main-menu" anchorEl={menuLink} open={isMenuOpen} onClose={handleMenuClose}>
        {options.map((option, index) =>
          <MenuItem
            {...option.attributes}
            key={index}
            onClick={() => handleMenuSelection(option.target)}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <Typography variant="inherit" color="primary">
              {option.title}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default withStyles(styles)(withRouter(Nav));
