import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/route/AppliedRoute";
import AuthenticatedRoute from "./components/route/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/route/UnauthenticatedRoute";
import UnregisteredRoute from "./components/route/UnregisteredRoute";
import InvalidRoute from "./components/route/InvalidRoute";
import Loadable from "react-loadable";
import ComponentLoadingIndicator from "./components/common/ComponentLoadingIndicator";

const LoadableHome = Loadable({
  loader: () => import("./containers/Home"),
  loading: ComponentLoadingIndicator,
  timeout: 10000 // 10 seconds
});

const LoadableLogin = Loadable({
  loader: () => import("./containers/Login"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableForgotPassword = Loadable({
  loader: () => import("./containers/ForgotPassword"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableSignUp = Loadable({
  loader: () => import("./containers/SignUp"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableSubscription = Loadable({
  loader: () => import("./containers/Subscription"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableHost = Loadable({
  loader: () => import("./containers/Host"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableProvider = Loadable({
  loader: () => import("./containers/Provider"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableConsumer = Loadable({
  loader: () => import("./containers/Consumer"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableDocumentsList = Loadable({
  loader: () => import("./containers/DocumentsList"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

const LoadableNotFound = Loadable({
  loader: () => import("./containers/NotFound"),
  loading: ComponentLoadingIndicator,
  timeout: 10000
});

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute
        path="/"
        exact
        component={LoadableHome}
        appProps={appProps}
        headerCaptionApplicable
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={LoadableLogin}
        appProps={appProps}
        headerCaptionApplicable
      />
      <UnauthenticatedRoute
        path="/login/:username"
        exact
        component={LoadableLogin}
        appProps={appProps}
        headerCaptionApplicable
      />
      <UnauthenticatedRoute
        path="/forgotPassword"
        exact
        component={LoadableForgotPassword}
        appProps={appProps}
        headerCaptionApplicable
      />
      <UnregisteredRoute
        path="/signup"
        exact
        component={LoadableSignUp}
        appProps={appProps}
        headerCaptionApplicable
        />
      <AuthenticatedRoute
        path="/subscription"
        exact
        component={LoadableSubscription}
        appProps={appProps}
        allowedOrgTypes={["host", "provider", "consumer"]}
      />
      <AuthenticatedRoute
        path="/host"
        exact
        component={LoadableHost}
        appProps={appProps}
        headerCaptionApplicable
        allowedOrgTypes={["host"]}
      />
      <AuthenticatedRoute
        path="/providers/:providerId"
        exact
        component={LoadableProvider}
        appProps={appProps}
        allowedOrgTypes={["host", "provider"]}
      />
      <AuthenticatedRoute
        path="/providers/:providerId/consumers/:consumerId"
        exact
        component={LoadableConsumer}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/providers/:providerId/consumers/:consumerId/folders/:folderId/documents"
        exact
        component={LoadableDocumentsList}
        appProps={appProps}
      />
      <InvalidRoute component={LoadableNotFound} appProps={appProps} headerCaptionApplicable />
    </Switch>
  );
}
