import React from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import FooterComponent from './FooterComponent';
import aboutUsBefore from './about_us_before.png';
import aboutUsAfter from './about_us_after.png';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from "@material-ui/core";
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  intro: {
    fontSize: 20,
    fontStyle: 'italic',
    color: grey[900]
  },
  punchline: {
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: grey[900]
  },
  finalNote: {
    fontSize: 24,
    fontWeight: 'bold',
    color: grey[900]
  }
});

function About({ triggerType, label = "About", classes, ...props }) {
  const Content = ({ tryFreeButton: TryFreeButton }) => {
    return (
      <>
        <DialogContentText>
        <img style={{width: '100%'}} src={aboutUsBefore} alt="it all started with missing files looking for tax returns leading to communication gap" />
        <br />
        <br />
        <br />
        <Typography component="span" className={classes.intro}>
          We are looking to fill the communication gap between you and your client.
        </Typography>
        <br />
        <Typography component="span" className={classes.punchline}>
          Let’s get on same page with FileStatz.
        </Typography>
        </DialogContentText>
        <DialogContentText>
          <br />
          <Typography component="span" className={classes.intro}>
          Our solution is to provide the ability to share any documents immediately with your clients that have been created or work in progress.
          </Typography>
          <img style={{width: '100%'}} src={aboutUsAfter} alt="all you need is a way to share the files with your clients safely, securely and instantaneously" />
          <br />
          <br />
          <br />
          <Typography component="span" className={classes.finalNote}>
            So, FIleStatz will bridge that gap while you can focus on serious business.
          </Typography>
        </DialogContentText>
        <TryFreeButton />
        <br />
        <br />
        <br />
        <Typography variant="h6" component="h2">
          About FileStatz.com
        </Typography>
        <DialogContentText>
          FileStatz.com (a division of Brandminds Corporation an Illinois Corporation) started operations in 2020 to provide a secure online environment for data storage and sharing of files globally for small businesses, professionals, and enterprises. We are a privately held corporation located in Chicago Illinois, USA. Our 20+ years of secure online data and file sharing, collaboration, and online document management experience has given us the opportunity to successfully help businesses of all types with their secure professional document delivery, collaboration and data storage needs in the Cloud.
        </DialogContentText>
        <DialogContentText>
          Our philosophy is simplifying the transitions between you (as Tax practitioners) and your customers so that you don't have to deal with unnecessary calls, email and even visit for status inquiries. So that you can focus on the important aspects of business such as tax planning, strategy and saving money for your customers.
        </DialogContentText>
        <DialogContentText>
          Our value proposition is that your cost-savings will be recovered within the 2 months of the use of our service. That means in a year you will make 10 months' profit from the investment into our service.
        </DialogContentText>
      </>
    );
  };

  return (
    <FooterComponent id="about" title="Our story" label={label} triggerType={triggerType} content={Content} {...props} />
  );
}

export default withStyles(styles)(About);
