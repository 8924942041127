import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { API } from "aws-amplify";

const ProviderConsumerContext = React.createContext();

function ProviderConsumerContextComponent({user, provider, setProvider, consumer, setConsumer, match: { params: { providerId, consumerId } }, children }) {
  const existingProviderId = provider && provider.providerId;
  const existingConsumerId = consumer && consumer.consumerId;
  
  useEffect(() => {

    async function onLoad() {
      if (!user) {
        return;
      }
      // provider
      if (user.orgType === "host") {
        if (providerId) {
          if (existingProviderId !== providerId) {
            setProvider(await API.get("FileStatz", `/providers/${providerId}`));
          }
        } else if (provider) {
          setProvider(undefined);
        }
      } else {
        if (!provider) {
          setProvider({ providerId: user.providerId, displayName: user.providerName });
        }
      }
    }
  
    onLoad();
  }, [providerId, existingProviderId, provider, setProvider, user]);

  useEffect(() => {

    async function onLoad() {
      if (!user) {
        return;
      }

      // consumer
      if (user.orgType === "host" || user.orgType === "provider") {
        if (consumerId) {
          if (existingConsumerId !== consumerId) {
            setConsumer(await API.get("FileStatz", `/providers/${providerId}/consumers/${consumerId}`));
          }
        } else if (consumer) {
          setConsumer(undefined);
        }
      } else {
        if (!consumer) {
          setConsumer({ consumerId: user.consumerId, displayName: user.consumerName });
        }
      }
    }
  
    onLoad();
  }, [providerId, consumerId, existingConsumerId, consumer, setConsumer, user]);

  return (
    <ProviderConsumerContext.Provider value={{ provider, consumer }}>
      {children}
    </ProviderConsumerContext.Provider>
  );
}

export default withRouter(ProviderConsumerContextComponent);

export { ProviderConsumerContext };