import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import Routes from "./Routes";

function App({ history }) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState(null);
  const [provider, setProvider] = useState(null);
  const [consumer, setConsumer] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentSession();
        try {
          const user = await API.get("FileStatz", "/me");
          setUser(user);
        } catch (e) {
          history.push("/login");
        }
        userHasAuthenticated(true);
      }
      catch(e) {
        if (e !== 'No current user') {
          alert(e);
        }
      }
    
      setIsAuthenticating(false);
    }
  
    onLoad();
  }, [history]);
  
  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setUser(null);
    setProvider(null);
    setConsumer(null);
    history.push("/login");
  }

  return (
    <Routes appProps={{ isAuthenticating, isAuthenticated, userHasAuthenticated, user, setUser, provider, setProvider, consumer, setConsumer, handleLogout }} />
  );
}

export default withRouter(App);
