import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  button: {
    margin: 0,
    padding: 0,
    display: "inline",
    verticalAlign: "top"
  },
  text: {
    "&:hover": {
      color: "black"
    },
    textTransform: "none",
    fontWeight: "bold"
  },
});

function FooterLink({ classes, children, ...props }) {
  return (
    <Button variant="text" {...props} className={classes.button}>
      <Typography variant="caption" color="textSecondary" className={classes.text}>
        {children}
      </Typography>
    </Button>
  );
}

export default withStyles(styles)(FooterLink);
