import React from "react";
import { withStyles, CircularProgress } from '@material-ui/core';

const styles = theme => ({
	centered: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2)
  }
});

function CenteredCircularProgress({ classes }) {
	return (
		<div className={classes.centered}>
			<CircularProgress />
    </div>
	);
}

export default withStyles(styles)(CenteredCircularProgress);
