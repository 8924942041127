import React from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import FooterComoponent from "./FooterComponent";

function Contact({ classes, triggerType, label = "Contact", ...props }) {
  return (
    <FooterComoponent id="contact" title="Contact FileStatz.com" label={label} triggerType={triggerType} {...props}>
      <DialogContentText>
        <strong>Email:</strong> <br />
        General inquiries: info@filestatz.com <br />
        Sales: sales@filestatz.com <br />
        Billing: billing@filestatz.com <br />
        Technical support for FileStatz.com: support@filestatz.com <br /><br />

        <strong>Phone:</strong> <br />
        224.444.9355 <br /><br />

        <strong>Mail:</strong> <br />
        FileStatz.com Division <br />
        Brandminds Corporation <br />
        850 Waukegan Rd <br />
        Northbrook, IL 60062
      </DialogContentText>
    </FooterComoponent>
  );
}

export default Contact;
