import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';

function Link(props) {
  return props.to
    ? <MaterialLink component={RouterLink} {...props} />
    : <MaterialLink {...props} />
}

export default Link;
