import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import CommonLayout from "../layout/CommonLayout";
import ProviderConsumerContext from '../context/ProviderConsumerContext';
import Authorizer from "./Authorizer";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

function AuthenticatedRoute({ component: C, appProps, headerCaptionApplicable, allowedOrgTypes, ...rest }) {
  const { isAuthenticating, isAuthenticated } = appProps;

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticating ?
        <CommonLayout><CenteredCircularProgress /></CommonLayout> :
        isAuthenticated ?
        <Authorizer {...props} {...appProps} allowedOrgTypes={allowedOrgTypes}><ProviderConsumerContext {...props} {...appProps}><CommonLayout {...props} {...appProps} headerCaptionApplicable={headerCaptionApplicable}><C {...props} {...appProps} /></CommonLayout></ProviderConsumerContext></Authorizer>
        : <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location
              .search}`}
          />}
    />
  );
}

export default withRouter(AuthenticatedRoute);