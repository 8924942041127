import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  button: {
    margin: 0,
    marginBottom: "1px",
    padding: 0
  },
  text: {
    textTransform: "none",
    fontWeight: "bold"
  },
});

function LinkButton({ classes, children, ...props }) {
  return (
    <Button variant="text" {...props} className={classes.button}>
      <Typography className={classes.text} color="primary">
        {children}
      </Typography>
    </Button>
  );
}

export default withStyles(styles)(LinkButton);
