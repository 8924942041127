import React from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import FooterComponent from "./FooterComponent";

const styles = theme => ({
  footNote: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  }
});

function Pricing({ triggerType, label = "Pricing", classes, ...props }) {
  return (
    <FooterComponent id="pricing" title="FileStatz.com Pricing" label={label} triggerType={triggerType} {...props}>
      <DialogContentText>
        Choose a plan that’s right for your business.
      </DialogContentText>
      <DialogContentText>
        Get started with your 30-day free trial or ask and we can even help you design the perfect plan for your business. No credit card required until Activation.
      </DialogContentText>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Basic plan</TableCell>
            <TableCell>Premium plan (Coming soon)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Cost</TableCell>
            <TableCell valign="top">
              <div>$19 monthly</div>
              <div>&nbsp;</div>
            </TableCell>
            <TableCell>
              <div>$59 monthly</div>
              <div>$649 yearly (12<sup>th</sup> month free)</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>30-day free trial</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number of customers</TableCell>
            <TableCell>100</TableCell>
            <TableCell>Unlimited<sup>*</sup></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Storage limit</TableCell>
            <TableCell>10GB</TableCell>
            <TableCell>Unlimited<sup>*</sup></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>File size limit</TableCell>
            <TableCell>5MB</TableCell>
            <TableCell>Unlimited<sup>*</sup></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email support</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Telephone support</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Emergency file recovery up to 48 hrs of deletion</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Activity Logs for Auditing</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Secure file upload</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Administrator controlled user permissions</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Self administration of user permissions</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Email notifications</TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Text notifications</TableCell>
            <TableCell></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Add/rename folders</TableCell>
            <TableCell></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Feature - Recent activity</TableCell>
            <TableCell></TableCell>
            <TableCell><CheckIcon color="primary" /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={classes.footNote}>
        <Typography variant="caption"><sup>*</sup> Subject to fair use policy</Typography>
      </div>
    </FooterComponent>
  );
}

export default withStyles(styles)(Pricing);
