import React from "react";
import { Route, Redirect } from "react-router-dom";
import CommonLayout from "../layout/CommonLayout"

export default function UnregisteredRoute({ component: C, appProps, headerCaptionApplicable, ...rest }) {

  return (
    <Route
      {...rest}
      render={props =>
        !appProps.user
          ? <CommonLayout {...props} {...appProps} headerCaptionApplicable={headerCaptionApplicable}><C {...props} {...appProps} /></CommonLayout>
          : <Redirect
              to="/"
            />}
    />
  );
}
