import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FooterLink from './FooterLink';
import LinkButton from '../common/LinkButton';

const styles = theme => ({
  title: {
    position: "relative",
  },
  titleText: {
    fontWeight: 900,
  },
  closeIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FooterComponent({ id, title, classes, triggerType, label, children, onLClick, isAuthenticated, history, location, content: Content }) {
  const [isOpen, setIsOpen] = useState(false);

  const TryFreeButton = () => {
    if(!isAuthenticated) {
      return <Button color="primary" variant="contained" onClick={handleSignUp}>Try FileStatz for FREE</Button>;
    } else {
      return null;
    }
  };
  
  function handleClick(event) {
    setIsOpen(true);
    onLClick && onLClick(event);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleSignUp() {
    setIsOpen(false);
    if (location.pathname !== "/signup") {
      history.push("/signup");
    }
  }

  let trigger = triggerType === "navLink" ?
    <MenuItem onClick={handleClick}>{label}</MenuItem> : triggerType === "footerLink" ?
    <FooterLink onClick={handleClick}>{label}</FooterLink> :
    <LinkButton onClick={handleClick}>{label}</LinkButton>;

  return (
    <>
      {trigger}
      <Dialog
        open={isOpen}
        fullScreen
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby={`${id}-footer-component-title`}
        aria-describedby={`${id}-footer-component-content`}
      >
        <Container maxWidth="md">
          <DialogTitle id={`${id}-footer-component-title`} disableTypography className={classes.title}>
            <Typography variant="h5" component="h1" className={classes.titleText}>{title}</Typography>
            <IconButton aria-label="close" className={classes.closeIconButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div id={`${id}-footer-component-content`}>
              {Content ? <Content tryFreeButton={TryFreeButton} /> : children}
            </div>
          </DialogContent>
          <DialogActions>
            <TryFreeButton />
            <Button color="primary" variant="contained" onClick={handleClose}>Close</Button>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(withRouter(FooterComponent));
