import React from "react";
import { Route } from "react-router-dom";
import CommonLayout from "../layout/CommonLayout";
import ProviderConsumerContext from "../context/ProviderConsumerContext";

export default function InvalidRoute({ component: C, appProps, headerCaptionApplicable, ...rest }) {

  return (
    <Route
      {...rest}
      render={props =>
        <ProviderConsumerContext {...props} {...appProps}><CommonLayout {...props} {...appProps} headerCaptionApplicable={headerCaptionApplicable}><C {...props} {...appProps} /></CommonLayout></ProviderConsumerContext>
      }
    />
  );
}
