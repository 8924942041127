import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Nav from './Nav';
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import About from "../footer/About";
import Pricing from "../footer/Pricing";
import TermsAndConditions from "../footer/TermsAndConditions";
import Privacy from "../footer/Privacy";
import Contact from "../footer/Contact";
import Link from "../common/Link";

const theme = createMuiTheme({
  overrides: {
    MuiLink: {
      root: {
        fontWeight: 600
      },
    },
  },
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#1a237e',
      primaryLight: '#534bae',
      primaryDark: '#000051',
      contrastText: '#fff'
    },
    secondary: {
      main: '#1976d2',
      secondaryLight: '#63a4ff',
      secondaryDark: '#004ba0',
      contrastText: '#fff'
    }
  }
});

const styles = theme => ({
  logo: {
    fontSize: "2.125rem",
    fontWeight: 400,
    color: "white",
    textDecoration: "none",
    '&:hover': {
      textDecoration: "none"
    }
  },
  companySeparator: {
    fontSize: "2.125rem",
    fontWeight: 300,
    color: "grey",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  headerCaption: {
    fontStyle: "italic",
    color: "lightGrey",
    verticalAlign: "middle"
  },
  providerName: {
    flexGrow: 1,
    fontSize: "1.3rem",
    color: "lightGrey",
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5)
  },
  footer: {
    backgroundColor: '#eee',
    width: '100%',
    padding: theme.spacing(2),
    marginTop: 'auto'
  },
  footerLinkSeparator: {
    color: 'grey',
    fontWeight: 100,
    '&::before': {
      content: '"|"',
    }
  },
  poweredBy: {
    float: "right",
    verticalAlign: "middle",
    lineHeight: "24px"
  }
});

function CommonLayout({ classes, isAuthenticated, isAuthenticating, headerCaptionApplicable, handleLogout, user, provider, children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={classes.appBar}>
          <Container>
            <Toolbar style={{ padding: 0 }}>
              <Link to="/" className={classes.logo}>
                FileStatz 
              </Link>
              {headerCaptionApplicable && !provider?
                <>
                  <Typography variant="h4" component="h1" className={classes.companySeparator}>
                    ｜
                  </Typography>
                  <Typography noWrap className={classes.headerCaption}>
                    Be on the same page with any file anywhere
                  </Typography>
                </>
              : null}
              {provider ?
                <>
                  <Typography variant="h4" component="h1" className={classes.companySeparator}>
                    ｜
                  </Typography>
                  <Typography noWrap className={classes.providerName}>{provider.displayName}</Typography>
                </>
              : null}
              {isAuthenticated
                ? <Nav user={user} logout={handleLogout} />
                : null
              }
            </Toolbar>
          </Container>
        </AppBar>
        <Container component="main" className={classes.main}>
          {isAuthenticating
            ? <CenteredCircularProgress />
            : children
          }
        </Container>
        <footer className={classes.footer}>
          <Container>
            <About triggerType="footerLink" isAuthenticated={isAuthenticated} />
            <span className={classes.footerLinkSeparator} />
            <Pricing triggerType="footerLink" isAuthenticated={isAuthenticated} />
            <span className={classes.footerLinkSeparator} />
            <TermsAndConditions triggerType="footerLink" isAuthenticated={isAuthenticated} />
            <span className={classes.footerLinkSeparator} />
            <Privacy triggerType="footerLink" isAuthenticated={isAuthenticated} />
            <span className={classes.footerLinkSeparator} />
            <Contact triggerType="footerLink" isAuthenticated={isAuthenticated} />
              <Typography variant="caption" color="textSecondary" align="right" noWrap className={classes.poweredBy}>
                Powered by BrandMinds
              </Typography>
          </Container>
        </footer>
      </div>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(CommonLayout);
